<script>
import { debounce } from 'lodash-es'
import DokomaBlob from 'dokoma-blob'
export default {
  name: 'connect',
  components: {
    DokomaBlob
  },
  data () {
    return {
      username: null,
      password: null,
      showPass: true,
      error: false,
      seed: 'dokoma',
      loading: false,
      backupOpt: {
        looping: false,
        couleurDebut: '#5465ff',
        couleurFin: '#0b22f2',
        nbPoint: 3,
        complexite: 20,
        contraste: 80,
        angularite: 100,
        duree: 7,
        transition: 'elastic',
        largeur: 1,
        radius: 100
      },
      blobData: {
        looping: false,
        couleurDebut: '#5465ff',
        couleurFin: '#0b22f2',
        nbPoint: 3,
        complexite: 20,
        contraste: 80,
        angularite: 100,
        duree: 7,
        transition: 'elastic',
        largeur: 1,
        radius: 100
      }
    }
  },
  methods: {
    errorBlob () {
      this.blobData = {
        contour: false,
        largeur: 0.5,
        nbPoint: 10,
        complexite: 100,
        contraste: 100,
        radius: 200,
        angularite: 0,
        couleurDebut: 'darkred',
        couleurFin: 'red',
        duree: 0.5,
        transition: 'glitch',
        seed: 'error'
      }
    },
    majBlob: debounce(function (type) {
      if (this.error) {
        this.error = false
        this.blobData = this.backupOpt
      }
      if (type === 'u') {
        this.seed = this.username
        if (this.username && this.username.length > 6 && this.username.length < 60) {
          this.blobData.nbPoint = this.username.length / 2
        } else {
          this.blobData.nbPoint = 3
        }
      } else {
        if (this.password) {
          this.blobData.radius = 100 + (this.password.length * 20)
        } else {
          this.blobData.radius = 100
        }
      }
    }, 500, { trailing: true }),
    login () {
      // Pour garder notre locale dans le sso
      this.$store.commit('App/setConnectError', false)
      this.$auth.options.providers.oauth2.locale = this.$i18n.locale
      this.$auth.authenticate('oauth2')
    }
  }
}
</script>
<template lang="pug">
  .connexion-index
    div.login-blob-wrapper(id='loginBlob')
      dokoma-blob(:rotating='true', :seed='seed', :idle='true', :blobOptions='blobData', :debugOptions='false')

    div.flex-grow-1

    div.connexion-introduction.relative
      h1.login-title {{$t('app.espace')}}
    div.flex-grow-1

    div.connexion-informations.relative
      v-alert(v-if='$store.state.App.connectError', primary, type='error') {{ $t('message.error-connect') }}
      v-btn.mt3(@click='login', block, rounded, color='dark', dark, large, style='font-size:20px;') Connexion
    div.flex-grow-1
</template>
<style lang='sass'>
  @import 'src/styles/pages/_connexion'

  .login-blob-wrapper
    position: fixed
    top: calc(50% - 30vh)
    top: calc(50% - var(--vh, 1vh) * 30)
    left: 0
    // bottom: 0
    width: 100vw
    height: 60vh
    display: flex
    justify-content: center
    align-items: center
    transition: all 0.25s ease-in
    .blob-wrapper
      // position: fixed
      // top: 0
      // left: 0
      height: 100%
      width: 100%
      #blob
        // max-width: 1000px
        width: 100%
        margin: 0 auto
        height: 0
        padding-top: 98%
        position: relative
        svg#svblob
          position: absolute
          top: 0
          left: 0
          height: 60vh !important
          width: 100vw !important

  // Options pour le package blob
  #svblob
    overflow: visible !important
  .rotation-animation
    animation: rotation 120s infinite linear
    -webkit-animation: rotation 120s infinite linear
  @-webkit-keyframes rotation
    from
      -webkit-transform: rotate(0deg)
    to
      -webkit-transform: rotate(359deg)
  .options-wrapper
    position: absolute
    background-color: rgba(255,255,255,0.5)
    padding: 20px
    margin: 20px
    border-radius: 10px
    top: 0
    right: 0
    z-index: 10
    width: 400px
    overflow-x: hidden
    font-size: 10px
    .param
      display: flex
      justify-content: space-between
      margin-bottom: 5px
</style>
